<template>
  <div class="mb-6">
    <button @click="goBack()"
      class="mt-5 rounded-full w-auto text-center focus:outline-none text-gray-700 dark:text-gray-400 font-semibold">
      &larr; go back
    </button>
    <h2 v-if="!editMode" class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Create new Customer
    </h2>
    <h2 v-else class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
      Edit Customer
    </h2>

    <div
      class="w-full md:w-3/4 shadow-lg bg-white dark:bg-gray-700 p-4 md:p-8 rounded-xl flex flex-col space-y-4 border border-gray-200">
      <div class="w-full">
        <label class="">
          <span class="label">Name</span>
          <input type="text" class="input" placeholder="Name" v-model="$v.customer.name.$model" />
        </label>
      </div>
      <div class="w-full flex flex-col space-y-2">
        <label class="">
          <span class="label">Mobile</span>
          <input type="text" class="input" placeholder="Mobile" v-model="$v.customer.mobile.$model" />
        </label>
        <p v-if="errors && errors.mobile[0]" class="text-red-600 dark:text-red-300 text-sm">
          {{ errors.mobile[0] }}
        </p>
      </div>
      <div class="w-full flex flex-col space-y-2">
        <label class="">
          <span class="label">Limit</span>
          <input type="text" class="input" placeholder="Limit" v-model="$v.customer.limit.$model" />
        </label>
        <p v-if="errors && errors.limit[0]" class="text-red-600 dark:text-red-300 text-sm">
          {{ errors.limit[0] }}
        </p>
      </div>
      <div class="w-full">
        <h2 class="label mb-2">Has customer verified their identity?</h2>
        <label class="inline-flex items-center text-gray-600 dark:text-gray-400">
          <input type="radio" class="text-primary-light form-radio focus:outline-none  focus:shadow-none"
            name="accountType" :value="true" v-model="$v.customer.verified.$model" />
          <span class="ml-2">Verified</span>
        </label>
        <label class="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400">
          <input type="radio" class="text-primary-light form-radio focus:outline-none focus:shadow-none"
            name="accountType" :value="false" v-model="$v.customer.verified.$model" />
          <span class="ml-2">Unverified</span>
        </label>
      </div>
      <div class="w-full flex justify-center">
        <button class="btn w-40" :class="
          $v.$invalid ? 'bg-gray-600 dark:bg-gray-400 text-white' : null
        " @click="editMode ? updateCustomer() : saveCustomer()">
          {{ editMode? "Update customer": "Create customer" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import isNil from "ramda/es/isNil";
export default {
  name: "CreatecustomerComponent",
  mixins: [validationMixin],
  data() {
    return {
      customer: {
        name: "",
        mobile: "",
        verified: false,
        limit: 251,
      },
    };
  },
  mounted() {
    if (this.editMode) {
      this.loadcustomer();
    }
  },
  computed: {
    ...mapGetters({
      errors: ["status/getErrors"],
      cust: ["customer/getCustomer"],
    }),
    editMode() {
      return !isNil(this.$route.params) && !isNil(this.$route.params.nano_id);
    },
  },
  validations: {
    customer: {
      name: {
        required,
      },
      verified: {
        required,
      },
      limit: {
        required,
        numeric,
      },
      mobile: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
  },
  methods: {
    loadcustomer() {
      this.$store.dispatch("customer/loadCustomer", {
        nano_id: this.$route.params.nano_id,
      });
    },
    saveCustomer() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("customer/storeCustomer", {
          customer: this.customer,
        });
      }
    },
    updateCustomer() {
      if (!this.$v.$invalid) {
        this.$store.dispatch("customer/updateCustomer", {
          nano_id: this.$route.params.nano_id,
          customer: this.customer,
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    cust: {
      handler() {
        if (!isNil(this.cust) && this.editMode) {
          this.customer = {
            ...this.cust,
          };
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>

</style>
